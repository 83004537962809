a,
.search-cat.navbar-default .navbar-nav li a.dropdown-toggle,
#site-header.v2 #main-menu .menu li.active > a,
#site-header.v2 #main-menu .menu > li > a:hover,
.page-section.v3-seven .title-1,
.woo-cart-empty .cart-empty,
.cart table.shop_table th,
.cart .cart-collaterals .cart_totals h2,
.cart table.wishlist_table th,
.cart .cart_totals_inner .coupon label,
.checkout h3,
.user-login h5,
.icon-box .text h4,
.widget {
	color: #4f7397;
}
a:hover,
a:focus,
#site-header .top a:hover,
.shop-cart .total .amount,
#main-menu .sub-menu li a:hover,
#main-menu li.mega-menu .mega-submenu li .mega-title,
#left-menu .menu .mega-menu .sub-menu li .mega-title,
#left-menu button,
#left-menu .menu .sub-menu li a:hover,
#site-header.v2 #main-menu .menu > li > a:hover,
.page-section.v3-five .row .col-md-6:last-child a,
.breadcrumbs li a:hover,
.entry-content .more-link,
.entry-meta .entry-title a:hover,
.post-meta a:hover,
.page-nav a:hover,
.page-nav .current,
.author-info .author-bio h3 span,
#related-posts .c-nav a:hover,
#related-posts figcaption a:hover,
.comment-respond .comment-form .form-submit input[type="submit"]:hover,
.error-404 ul li a,
.page-section.faq-one .icon-box.style-3 .icons,
.cart table.cart .product-name > a:hover,
.cart table.cart .amount,
.cart table.cart .amount,
.cart .cart_totals_inner .wc-proceed-to-checkout input.button:hover,
.shop-details .p-meta a,
#p-thumb .owl-controls .owl-buttons > div:hover,
#site-footer .bot .bot-main a:hover,
#site-footer .widget a:hover,
.shop-cart .shop-item .shop-item-title i:hover,
.shop-cart ul.cart_list li a:hover,
.shop-cart .buttons a.button:hover,
.icon-list li i,
.product .p-badge.sale,
.product .p-info .p-action a:hover i,
.product.style-2 .p-image a:hover i,
.product-tabs .nav-tabs > li.active > a,
.blog-post .entry-meta h4 a:hover,
.blog-post .entry-content .more-link,
.social li a:hover,
.wr_widget_recent_posts .post-item a:hover,
.wr_widget_recent_posts .wrp-meta span.price,
.btn-dashed:hover,
.product .p-info .price .amount {
	color: #E64F96;
}
.shop-cart ul.cart_list li .remove:hover {
	color: #E64F96 !important !important;
}
.cart-control:hover .cart-item span,
.shop-cart .buttons a.button.checkout,
#main-menu .menu li.active > a,
#main-menu .menu > li > a:hover,
#related-posts figure .mask a:hover,
.page-section.contact-two .btn,
.page-section.faq-three,
.newsletter .modal-content .close,
.page-section.about-one,
.woo-cart-empty .return-to-shop .button,
.cart .cart_totals_inner .wc-proceed-to-checkout a.button,
.checkout #payment #place_order,
.radio-styled input:checked ~ .inner:after,
.icon-box.style-3 .icons,
.icon-box.style-4 .icons,
.icon-box.style-5 .icons,
.icon-list li i,
.product .p-badge.sale,
.subscribe.style-2 .btn,
.contact-form.style-1 .btn,
.widget .f-price .ui-slider .ui-slider-range,
.widget .f-price .ui-state-default,
.btn-main {
	background: #E64F96;
}
.shop-cart .buttons a.button.checkout,
.menu-box li a:hover,
.comments-area .action-link a:hover,
.comment-respond .comment-form .form-submit input[type="submit"]:hover,
.product .p-info .p-action a:hover,
.product.style-2 .p-image a:hover,
.social li a:hover,
.widget .f-color li:hover,
.btn-dashed:hover {
	border-color: #E64F96;
}
.shop-cart .buttons a.button.checkout:hover,
.page-section.contact-two .btn:hover,
.woo-cart-empty .return-to-shop .button:hover,
.cart .cart_totals_inner .wc-proceed-to-checkout a.button:hover,
.cart #payment #place_order:hover,
.cart .woo-cart-empty .return-to-shop .button:hover,
.cart form input[type="submit"]:hover,
.subscribe .btn:hover,
.btn-main:hover {
	background: #c62c15;
}
body,
.shop-cart .shop-item,
.shop-cart .shop-item .shop-item-title,
.shop-cart .buttons a.button,
.blog-main .post .entry-content,
.page-nav a:after,
.page-nav .current:after,
.nav-shop .dropdown-toggle i,
.blog-single .post-meta > span,
.comments-area .comment-body,
.comments-area .comment-body .comment-meta time,
.comment-respond .comment-form .form-submit:before,
.checkout input,
.checkout form .form-row label,
.checkout table.shop_table .shipping td,
.checkout #payment div.payment_box,
.user-login label,
#p-thumb .owl-controls .owl-buttons > div,
.shop-details .nav-tabs > li > a,
.product .p-info .p-action a i,
.product.style-2 .p-image a i,
.wr_widget_recent_posts .wrp-meta span,
.panel-title i:after,
.member,
.social li a {
	color: #3c3e43;
}

.shop-cart .shop-item,
.shop-cart .shop-item .shop-item-title
{
	color: #E64F96;
}

.cart table.cart .product-remove a,
.cart table.wishlist_table .product-remove a {
	color: #3c3e43 !important;
}
.comment-respond .comment-form [class*="comment-form"] input:focus,
.comment-respond .comment-form .comment-form-comment textarea:focus,
.panel-title i:before {
	border-color: #3c3e43;
}
.page-section.services-three .icon-box .icons,
.cart .cart_totals_inner .coupon .button,
.cart .cart-collaterals .cart_totals table .shipping-calculator-form .button,
.cart form.checkout_coupon input.button,
.cart form.login input.button,
.cart form.lost_reset_password input.button,
.cart table.wishlist_table .button,
.user-login .coupon .button,
#footer-menu .menu > li:after,
.subscribe .btn {
	background: #3c3e43;
}
h1,
h2,
h3,
h4,
h5,
h6,
#site-header .top,
#site-header .top a,
.cart-control,
.entry-meta .entry-title a,
.blog-single .entry-content,
.single-share,
#related-posts figcaption a,
.comments-area .comment-body .comment-author,
.comments-area .comment-body .comment-author a,
.error-404 ul li a:hover,
.shop-main .products.list .product .p-info h4 a,
.cart .cart-collaterals .cart_totals table th,
.cart table.cart .product-name > a,
.checkout table.shop_table td,
.checkout table.shop_table th,
.checkout #payment ul.payment_methods label,
.shop-details .p-rate .pull-right,
.shop-details .p-meta ul li span,
.shop-details .p-share span,
#p-preview .owl-buttons > div,
#p-preview .item .zoom i,
.shop-details .nav-tabs > li.active a,
.shop-details #reviews #review_form_wrapper .btn-dashed,
.shop-details #reviews #comments ol.commentlist li .comment-text .meta strong,
.icon-box.style-4 .text h4,
.icon-list li,
.blog-post .entry-meta h4 a,
.blog-post .entry-content .more-link:hover,
.subscribe .form-group .form-control,
.widget .widget-title,
.wr_widget_recent_posts .post-item a,
.widget .f-price .btn,
.member .name,
.member .social-member a,
#site-footer .bot .bot-main,
#site-footer .bot .bot-main a,
#site-footer .widget,
#site-footer .widget a {
	color: #747880;
}
#site-header .top,
#top-menu .menu .sub-menu,
.cart-control .cart-item span,
.topbar,
.page-section.faq-one,
.modal-backdrop,
.offline #site-header,
#site-footer .bot .bot-main,
.member .social-member a:hover {
	background: #3c3e43;
}
.shop-cart .shop-item .shop-item-title,
.shop-cart ul.cart_list li,
.shop-cart .buttons a.button {
	border-color: #747880;
}
.topbar .page-title {
	background: #2d2f34;
}