/* Preload images */
body:after {
	content: url(../img/icons/close.png) url(../img/icons/loading.gif) url(../img/icons/prev.png) url(../img/icons/next.png);
	display: none;
}
.lightboxOverlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: black;
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	opacity: 0.8;
	display: none;
}
.lightbox {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 10000;
	text-align: center;
	line-height: 0;
	font-weight: normal;
}
.lightbox .lb-image {
	display: block;
	height: auto;
	max-width: inherit;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	border-radius: 3px;
}
.lightbox a img {
	border: none;
}
.lb-outerContainer {
	position: relative;
	background-color: white;
 *zoom: 1;
	width: 250px;
	height: 250px;
	margin: 0 auto;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
}
.lb-outerContainer:after {
	content: "";
	display: table;
	clear: both;
}
.lb-container {
	padding: 4px;
}
.lb-loader {
	position: absolute;
	top: 43%;
	left: 0;
	height: 25%;
	width: 100%;
	text-align: center;
	line-height: 0;
}
.lb-cancel {
	display: block;
	width: 32px;
	height: 32px;
	margin: 0 auto;
	background: url(../img/icons/loading.gif) no-repeat;
}
.lb-nav {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
}
.lb-container > .nav {
	left: 0;
}
.lb-nav a {
	outline: none;
	background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}
.lb-prev,
.lb-next {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-nav a.lb-prev {
	width: 34%;
	left: 0;
	float: left;
	background: url(../img/icons/prev.png) left 48% no-repeat;
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	-o-transition: opacity 0.6s;
	transition: opacity 0.6s;
}
.lb-nav a.lb-prev:hover {
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}
.lb-nav a.lb-next {
	width: 64%;
	right: 0;
	float: right;
	background: url(../img/icons/next.png) right 48% no-repeat;
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	-webkit-transition: opacity 0.6s;
	-moz-transition: opacity 0.6s;
	-o-transition: opacity 0.6s;
	transition: opacity 0.6s;
}
.lb-nav a.lb-next:hover {
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}
.lb-dataContainer {
	margin: 0 auto;
	padding-top: 5px;
 *zoom: 1;
	width: 100%;
	-moz-border-radius-bottomleft: 4px;
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.lb-dataContainer:after {
	content: "";
	display: table;
	clear: both;
}
.lb-data {
	padding: 0 4px;
	color: #ccc;
}
.lb-data .lb-details {
	width: 85%;
	float: left;
	text-align: left;
	line-height: 1.1em;
}
.lb-data .lb-caption {
	font-size: 13px;
	font-weight: bold;
	line-height: 1em;
}
.lb-data .lb-number {
	display: block;
	clear: left;
	padding-bottom: 1em;
	font-size: 12px;
	color: #999999;
}
.lb-data .lb-close {
	display: block;
	float: right;
	width: 30px;
	height: 30px;
	background: url(../img/icons/close.png) top right no-repeat;
	text-align: right;
	outline: none;
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
	opacity: 0.7;
	-webkit-transition: opacity 0.2s;
	-moz-transition: opacity 0.2s;
	-o-transition: opacity 0.2s;
	transition: opacity 0.2s;
}
.lb-data .lb-close:hover {
	cursor: pointer;
 filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}
