.wr-element {
	position: relative;
}
.wr-element:before,
.wr-element:after {
	content: "";
	display: table;
}
.wr-element:after {
	clear: both;
}
.mgt10 { margin-top: 10px; }
.mgt20 { margin-top: 20px; }
.mgt30 { margin-top: 30px; }
.mgt40 { margin-top: 40px; }
.mgt50 { margin-top: 50px; }
.mgt60 { margin-top: 60px; }
.mgt70 { margin-top: 70px; }
.mgt80 { margin-top: 80px; }
.mgt90 { margin-top: 90px; }
.mgt100 { margin-top: 100px; }
.mgb10 { margin-bottom: 10px; }
.mgb20 { margin-bottom: 20px; }
.mgb30 { margin-bottom: 30px; }
.mgb40 { margin-bottom: 40px; }
.mgb50 { margin-bottom: 50px; }
.mgb60 { margin-bottom: 60px; }
.mgb70 { margin-bottom: 70px; }
.mgb80 { margin-bottom: 80px; }
.mgb90 { margin-bottom: 90px; }
.mgb100 { margin-bottom: 100px; }
.mgl10 { margin-left: 10px; }
.mgl20 { margin-left: 20px; }
.mgl30 { margin-left: 30px; }
.mgl40 { margin-left: 40px; }
.mgl50 { margin-left: 50px; }
.mgr10 { margin-right: 10px; }
.mgr20 { margin-right: 20px; }
.mgr30 { margin-right: 30px; }
.mgr40 { margin-right: 40px; }
.mgr50 { margin-right: 50px; }
.pdt10 { padding-top: 10px; }
.pdt20 { padding-top: 20px; }
.pdt30 { padding-top: 30px; }
.pdt40 { padding-top: 40px; }
.pdt50 { padding-top: 50px; }
.pdt60 { padding-top: 60px; }
.pdt70 { padding-top: 70px; }
.pdt80 { padding-top: 80px; }
.pdt90 { padding-top: 90px; }
.pdt100 { padding-top: 100px; }
.pdb10 { padding-bottom: 10px; }
.pdb20 { padding-bottom: 20px; }
.pdb30 { padding-bottom: 30px; }
.pdb40 { padding-bottom: 40px; }
.pdb50 { padding-bottom: 50px; }
.pdb60 { padding-bottom: 60px; }
.pdb70 { padding-bottom: 70px; }
.pdb80 { padding-bottom: 80px; }
.pdb90 { padding-bottom: 90px; }
.pdb100 { padding-bottom: 100px; }
.pdl10 { padding-left: 10px; }
.pdl20 { padding-left: 20px; }
.pdl30 { padding-left: 30px; }
.pdl40 { padding-left: 40px; }
.pdl50 { padding-left: 50px; }
.pdr10 { padding-right: 10px; }
.pdr20 { padding-right: 20px; }
.pdr30 { padding-right: 30px; }
.pdr40 { padding-right: 40px; }
.pdr50 { padding-right: 50px; }

/* Icon Box
   ========================================================================== */
.icon-box {
	padding: 30px 0;
}
.icon-box .icons {
	color: #b0aeab;
	float: left;
	font-size: 40px;
}
.icon-box .text {
	overflow: hidden;
	padding-left: 15px;
}
.icon-box .text h4 {
	font-size: 14px;
	color: #685e52;
	text-transform: uppercase;
	font-weight: 600;
	margin: 13px 0 2px;
}
.icon-box .text p {
	color: #b2b2b2;
	font-size: 13px;
}
.icon-box.style-2 .icons {
	font-size: 44px;
}
.icon-box.style-2 .text h4 {
	font-weight: 500;
	font-size: 16px;
	margin: 23px 0 0;
}
.icon-box.style-3 .icons,
.icon-box.style-4 .icons,
.icon-box.style-5 .icons {
	width: 60px;
	height: 60px;
	background: #95c03e;
	color: #fff;
	font-size: 26px;
	text-align: center;
	line-height: 56px;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.icon-box.style-3 .text h4 {
	font-size: 16px;
	color: #fff;
	line-height: 20px;
	margin-top: 10px;
}
.icon-box.style-4 .icons {
	width: 42px;
	height: 42px;
	line-height: 38px;
}
.icon-box.style-4 .icons {
	font-size: 16px;
}
.icon-box.style-4 .text {
	overflow: visible;
	padding: 0;
}
.icon-box.style-4 .text h4 {
	color: #39342e;
	left: 60px;
	position: relative;
	top: -33px;
	font-size: 16px;
}
.icon-box.style-4 .text p {
	margin-top: -10px;
}
.icon-box.style-5 {
	text-align: center;
}
.icon-box.style-5 .icons {
	width: 90px;
	height: 90px;
	margin: auto;
	float: none;
	background: #87b034;
	font-size: 42px;
	line-height: 90px;
}
.icon-box.style-5 .text {
	padding: 0;
}
.icon-box.style-5 .text h4 {
	font-size: 16px;
	margin: 24px 0 16px;
}

/* Icon List
   ========================================================================== */
.icon-list {
	list-style: none;
}
.icon-list li {
	margin-bottom: 15px;
	color: #39342e;
}
.icon-list li i {
	width: 14px;
	height: 14px;
	background: #95c03e;
	color: #fff;
	font-size: 10px;
	text-align: center;
	line-height: 14px;
	margin-right: 5px;
	-webkit-border-radius: 50%;
	-moz-border-radius:    50%;
	-ms-border-radius:     50%;
	border-radius:         50%;
}
.icon-list.style-2 li i {
	background: none;
	color: #95c03e;
}

/* Product
   ========================================================================== */
.products[class*="columns-"] .product {
	float: left;
	margin: 20px 0;
}
.products.columns-6 .product {
	width: 16.6%;
}
.products.columns-5 .product {
	width: 20%;
}
.products.columns-4 .product {
	width: 25%;
}
.products.columns-3 .product {
	width: 33.3%;
}
.products.columns-2 .product {
	width: 50%;
}
.products.columns-6 .product:nth-child(7n),
.products.columns-5 .product:nth-child(6n),
.products.columns-4 .product:nth-child(5n),
.products.columns-3 .product:nth-child(4n),
.products.columns-2 .product:nth-child(3n) {
	clear: right;
}
.product.has-border {
	border: 1px solid #e8e6e2;
}
.product .p-badge {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 11px;
	color: #fff;
	background: #ff6565;
	text-transform: uppercase;
	padding: 8px 12px;
	font-weight: 600;
}
.product .p-badge.exclusive {
	background: #5d7ac3;
}
.product .p-badge.sale,
.product .p-badge.hot {
	font-size: 11px;
	text-shadow: 0 1px 1px rgba(0, 0, 0, .1);
	right: 10px;
	left: auto;
	padding: 3px 10px;
	text-transform: none;
}
.product .p-badge.sale {
	background: #95c03e;
}
.product .p-info .cat {
	font-size: 10px;
	text-transform: uppercase;
	color: #aeaeae;
	font-weight: 600;
	letter-spacing: 2px;
}
.product .p-info h4 {
	font-weight: 500;
}
.product .p-info .price {
	display: inline-block;
	margin-bottom: 10px;
}
.product .p-info .price .amount {
	font-size: 20px;
	color: #95c03e;
}
.product .p-info .price del {
	font-size: 14px;
	color: #b2b2b2;
}
.product .p-info .star-rating {
	font-family: FontAwesome;
	font-size: 11px;
	height: 1em;
	line-height: 1;
	overflow: hidden;
	position: relative;
	width: 62px;
	letter-spacing: 2.5px;
}
.product .p-info .star-rating:before {
	color: #c5c5c5;
	content: "\f005\f005\f005\f005\f005";
	float: left;
	left: 0;
	position: absolute;
	top: 0;
}
.product .p-info .star-rating span {
	float: left;
	left: 0;
	overflow: hidden;
	padding-top: 1.5em;
	position: absolute;
	top: 0;
}
.product .p-info .star-rating span:before {
	content: "\f005\f005\f005\f005\f005";
	left: 0;
	position: absolute;
	top: 0;
	color: #fdc75d;
}
.product .p-info .p-action {
	display: inline-block;
	width: 100%;
	margin-top: 32px;
}
.product .p-info .p-action a,
.product.style-2 .p-image a {
	float: left;
	height: 40px;
	width: 40px;
	line-height: 38px;
	border: 1px dashed #d1d1d1;
	text-align: center;
	margin-right: 10px;
	position: relative;
}
.product.style-2 .p-info .p-action a {
	margin-right: 0;
}
.product .p-info .p-action a:hover,
.product.style-2 .p-image a:hover {
	border-color: #95c03e;
}
.product .p-info .p-action a.add-to-cart {
	width: 138px;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
}
.product .p-info .p-action a i,
.product.style-2 .p-image a i {
	color: #a0978d;
	font-size: 18px;
	position: relative;
	top: 2px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.product .p-info .p-action a.add-to-cart i {
	margin-right: 5px;
}
.product .p-info .p-action a:hover i,
.product.style-2 .p-image a:hover i {
	color: #95c03e;
}
.product .p-info .p-action a span,
.product.style-2 .p-image a span {
	background: rgba(0, 0, 0, 0.7);
	bottom: 10px;
	color: #ffffff;
	font-size: 11px;
	height: auto;
	left: 50%;
	line-height: 20px;
	margin-left: -55px;
	opacity: 0;
	padding: 4px 6px;
	position: absolute;
	width: 110px;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.product .p-info .p-action a.quickview span,
.product.style-2 .p-image a.quickview span {
	width: 70px;
	margin-left: -35px;
}
.product .p-info .p-action a span:after,
.product.style-2 .p-image a span:after {
	border-left: 5px solid rgba(0, 0, 0, 0);
	border-right: 5px solid rgba(0, 0, 0, 0);
	border-top: 5px solid rgba(0, 0, 0, 0.7);
	bottom: -5px;
	content: "";
	height: 0;
	left: 50%;
	margin-left: -4px;
	position: absolute;
	width: 0;
}
.product .p-info .p-action a:hover > span,
.product.style-2 .p-image a:hover > span {
	bottom: 45px;
	opacity: 1;
}
.product .p-countdown {
	margin-top: 32px;
}
.product .p-countdown > span {
	background: #3d3d3d;
	color: #fff;
	font-size: 24px;
	width: 52px;
	height: 52px;
	text-align: center;
	line-height: 20px;
	padding-top: 10px;
	display: block;
	float: left;
	margin-right: 7px;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.product .p-countdown > span span {
	display: block;
	font-size: 8px;
	color: #b2b2b2;
	text-transform: uppercase;
}
.product .p-countdown.light > span {
	background: #fff;
	color: #6e6e6e;
	border: 1px solid #e7e7e7;
}
.product.style-1 {
	overflow: hidden;
}
.product.style-1 > div {
	float: left;
	width: 50%;
}
.product.style-1 .p-image img {
	margin-top: 40px;
	padding: 10px;
}
.product.style-1 .p-info {
	padding: 35px 15px;
}
/* Product - Style 2
   ========================================================================== */
.product.style-2 {
	text-align: center;
	padding: 10px 0;
}
.product.style-2 .p-image {
	position: relative;
}
.product.style-2 .p-image:before {
	content: "";
	position: absolute;
	background: rgba(0, 0, 0, .1);
	left: 10px;
	right: 10px;
	height: 100%;
	opacity: 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.product.style-2 .p-image:hover:before {
	opacity: 1;
}
.product.style-2 .p-image .p-action {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	height:100%;
}
.product.style-2 .p-image a {
	background: #fff;
	opacity: 0;
	top: 50%;
	-webkit-transform: translateY(-100%);
	-moz-transform:    translateY(-100%);
	-ms-transform:     translateY(-100%);
	-o-transform:      translateY(-100%);
	transform:         translateY(-100%);
}
.product.style-2 .p-image:hover a {
	opacity: 1;
	-webkit-transform: translateY(-50%);
	-moz-transform:    translateY(-50%);
	-ms-transform:     translateY(-50%);
	-o-transform:      translateY(-50%);
	transform:         translateY(-50%);
}
.product.style-2 .p-image a.quickview {
	margin: 0;
	-webkit-transition-delay: .1s;
	-moz-transition-delay:    .1s;
	transition-delay:         .1s;
}
.product.style-2 .p-info h4 {
	font-size: 14px;
	margin: 20px 0 5px;
}
.product.style-2 .p-info .price .amount {
	font-size: 16px;
}
.product.style-2 .p-info .price del {
	font-size: 12px;
}
.product.style-2 .p-action {
	margin-top: 10px;
}
.product.style-2 .p-action a {
	display: inline-block;
	float: none;
}
/* Product - Style 3
   ========================================================================== */
.product.style-3 .p-info {
	overflow: hidden;
	padding: 15px 20px;
}
.product.style-3 .p-info > div {
	float: left;
}
.product.style-3 .p-info .p-title {
	width: 70%;
}
.product.style-3 .p-info .p-action {
	width: 30%;
	margin: 0;
}
.product.style-3 .p-info h4 {
	font-size: 14px;
	margin: 0 0 5px;
}
.product.style-3 .p-info .price,
.product.style-3 .p-info .p-action a.add-to-cart {
	margin: 0;
}
.product.style-3 .p-info .price .amount {
	font-size: 16px;
}
.product.style-3 .p-info .p-action a.add-to-cart {
	float: right;
}
/* Product - Style 4
   ========================================================================== */
.product.style-4 {
	overflow: hidden;
}
.product.style-4.has-border {
	padding: 20px;
}
.product.style-4 > div {
	float: left;
}
.product.style-4 .p-image {
	width: 30%;
}
.product.style-4 .p-image a:hover {
	opacity: .5;
}
.product.style-4 .p-image img {
	border: 1px solid #e8e6e2;
}
.product.style-4 .p-info {
	width: 70%;
	padding-left: 18px;
}
.product.style-4 .p-info h4 {
	font-size: 13px;
	margin-bottom: 0;
	margin-top: 4px;
}
.product.style-4 .p-info .price .amount {
	font-size: 16px;
}
.product.style-4 .p-info .p-action {
	margin-top: 2px;
}
.product.style-4 .p-info .p-action a.add-to-cart {
	width: 128px;
	height: 34px;
	line-height: 34px;
}
.product.style-4 .p-info .p-action a i {
	font-size: 15px;
	top: 0;
}
/* Product - Tabs
   ========================================================================== */
.product-tabs {
	overflow: hidden;
}
.product-tabs.has-border {
	border: 1px solid #e8e6e2;
}
.product-tabs .nav-tabs .title-1 {
	margin: 15px 0 0;
	padding: 0;
}
.product-tabs .nav-tabs .title-1,
.product-tabs .nav-tabs > li > a,
.product-tabs .nav-tabs > li.active > a,
.product-tabs .nav-tabs > li.active > a:focus,
.product-tabs .nav-tabs > li.active > a:hover,
.product-tabs .nav-tabs > li > a:focus,
.product-tabs .nav-tabs > li > a:hover {
	border: 0;
}
.product-tabs .nav-tabs > li > a:focus,
.product-tabs .nav-tabs > li > a:hover {
	background: none;
}
.product-tabs .nav-tabs > li.active,
.product-tabs.style-2 .product {
	margin: 0;
}
.product-tabs .nav-tabs {
	margin: 0 20px;
}
.product-tabs .nav-tabs > li > a {
	padding: 15px;
}
.product-tabs .nav-tabs > li.active > a {
	color: #95c03e;
}
.product-tabs.right .nav-tabs {
	text-align: right;
}
.product-tabs.right .nav-tabs > li {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	float: none;
	display: inline-block;
}
.product-tabs.right .nav-tabs > li:first-child {
	float: left;
}
.product-tabs.right .nav-tabs > li:after {
	background: #eaeaea;
	content: "";
	height: 20px;
	position: absolute;
	right: 0;
	top: 13px;
	width: 1px;
	-webkit-transform: rotate(15deg);
	-moz-transform:    rotate(15deg);
	-ms-transform:     rotate(15deg);
	-o-transform:      rotate(15deg);
	transform:         rotate(15deg);
}
.product-tabs.right .nav-tabs > li:first-child:after {
	display: none
}
.product-tabs.right .nav-tabs > li:last-child:after {
	background: none;
}
.product-tabs.has-border .tab-content {
	padding: 0 10px;
}
.product-tabs.style-2.has-border .tab-content {
	padding: 0;
}
.product-tabs.style-2 .nav-tabs {
	margin: 0;
	border: 0;
}
.product-tabs.style-2 .nav-tabs > li:after {
	background: none;
}
.product-tabs.style-2 .nav-tabs .title-1 {
	margin-left: 20px;
}
.product-tabs.style-2 .nav-tabs > li > a,
.product-tabs.style-2 .nav-tabs > li > a:hover,
.product-tabs.style-2 .nav-tabs > li > a:focus {
	padding: 18px 15px;
	border-left: 1px solid #e8e6e2;
	border-radius: 0;
	margin: 0;
}
.product-tabs.style-2 .product.style-2 {
	padding: 20px 0;
	border-right: 0;
	border-bottom: 0;
}
.product-tabs.style-2 .product:nth-child(4n+1) {
	border-left: 0;
}

/* Blog post
   ========================================================================== */
.blog-post {
	margin-bottom: 24px;
	overflow: hidden;
}
.blog-post .entry-thumb {
	position: relative;
}
.blog-post .entry-thumb .thumb {
	display: block;
	position: relative;
}
.blog-post .entry-thumb a.thumb:before {
	background: rgba(0, 0, 0, .4);
	content: "";
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.blog-post .entry-thumb a.thumb:hover:before {
	opacity: 1;
}
.blog-post .entry-thumb .cat {
	position: absolute;
	color: #fff;
	background: #ff6565;
	text-transform: uppercase;
	font-size: 12px;
	z-index: 1;
	padding: 10px 15px;
	font-weight: 600;
	bottom: 110px;
	left: 30px;
}
.blog-post .entry-thumb .cat:hover {
	background: #e84040;
}
.blog-post .entry-meta h4 {
	margin: 0 0 10px;
	font-weight: 600;
}
.blog-post .entry-meta h4 a,
.blog-post .entry-content .more-link:hover {
	color: #39342e;
}
.blog-post .entry-meta h4 a:hover {
	color: #95c03e;
}
.blog-post .post-meta {
	border-bottom: 1px dashed #c2c2c2;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
.blog-post .entry-content p {
	margin-bottom: 10px;
}
.blog-post .entry-content .more-link {
	background: url("../img/icons/read-more.png") no-repeat 0 3px;
	color: #95c03e;
	padding-left: 21px;
}
.blog-post.style-1 .entry-content {
	margin: -100px 0 0 30px;
	width: 70%;
	background: #fff;
	padding: 20px 30px 0;
	position: relative;
}
.blog-post.style-2 .post > div {
	float: left;
	width: 50%;
}
.blog-post.style-2 .post .entry-thumb {
	padding-right: 16px;
}
.blog-post.style-2 .post .entry-content {
	padding-left: 14px;
}
.blog-post.style-2 .entry-meta h4 {
	font-size: 14px;
	font-weight: 500;
}
.blog-post.style-3 .entry-meta h4 {
	text-transform: uppercase;
	margin-top: 25px;
	line-height: 25px;
	font-weight: 700;
	font-size: 14px;
}
.blog-post.style-3 .entry-content p {
	color: #999;
}

/* Subscribe form
   ========================================================================== */
.subscribe {
	position: relative;
}
.subscribe .form-group:before {
	background: url("../img/icons/mail.png") no-repeat 0 0;
	content: "";
	height: 12px;
	left: 11px;
	position: absolute;
	top: 11px;
	width: 18px;
}
.subscribe .form-group .form-control,
.subscribe .btn {
	font-size: 11px;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.subscribe .form-group .form-control {
	color: #39342e;
	padding-left: 35px;
	width: 266px;
	border: 1px solid #e8e6e2;
	box-shadow: none;
}
.subscribe .btn {
	background: #a0978d;
	color: #fff;
	text-transform: uppercase;
	border: 0;
	padding: 9px 18px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.subscribe .btn:hover {
	background: #766a5d;
}
.form-control::-moz-placeholder {
	color: #d0ccc7;
}
.subscribe.style-2 .form-group:before {
	background: none
}
.subscribe.style-2 .form-group .form-control {
	background: #eee;
	border: 1px solid #d1d1d1;
	width: 420px;
}
.subscribe.style-2 .form-group .form-control,
.subscribe.style-2 .btn {
	height: 50px;
}
.subscribe.style-2 .btn {
	font-size: 14px;
	background: #95c03e;
	font-weight: 600;
	padding: 9px 28px;
	margin-left: 20px;
}
.subscribe.style-2 .btn:hover {
	background: #78a224;
}

/* Contact form
   ========================================================================== */
.contact-form.style-1 .form-control {
	background: none;
	border-color: #494949;
	border-radius: 0;
	font-family: Georgia;
	font-size: 14px;
	font-style: italic;
	padding: 0;
	color: #9d9d9d;
}
.contact-form.style-1 input.form-control {
	border-width: 0 0 1px;
	border-style: solid;
	height: 50px;
}
.contact-form.style-1 .form-control::-moz-placeholder {
	color: #515151;
}
.contact-form.style-1 .form-control:focus {
	box-shadow: none;
}
.contact-form.style-1 .btn {
	background: #95c03e;
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	border: 0;
	margin-top: 14px;
	padding: 15px 20px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.contact-form.style-1 .btn:hover {
	background: #78a224;
}
.contact-form.style-2 label {
	font-size: 11px;
	color: #d0ccc7;
	font-weight: 400;
}
.contact-form.style-2 label sup {
	color: #cf212c;
	font-size: 13px;
}
.contact-form.style-2 .form-control {
	border-color: #83807c;
	background: rgba(255, 255, 255, .1);
	box-shadow: none;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}

/* Social
   ========================================================================== */
.social {
	margin: 0;
	list-style: none;
}
.social li {
	display: inline-block;
	margin-left: 5px;
}
.social li a {
	width: 35px;
	height: 35px;
	border: 1px solid #e8e6e2;
	color: #aaa297;
	display: block;
	text-align: center;
	line-height: 34px;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.social li a:hover {
	border-color: #95c03e;
	color: #95c03e;
}

/* Widgets
   ========================================================================== */
.widget {
	border: 1px solid #e8e6e2;
	padding: 20px;
	margin-bottom: 20px;
	font-weight: 500;
	color: #685e52;
}
.widget .widget-title {
	font-size: 14px;
	color: #39342e;
	text-transform: uppercase;
	border-bottom: 1px solid #e8e6e2;
	margin-top: 0;
	margin-bottom: 20px;
	padding-bottom: 20px;
	font-weight: 700;
}
.widget ul {
	margin: 0;
	list-style: none;
}
.widget ul li {
	text-transform: uppercase;
	font-size: 11px;
}
.widget ul li.recentcomments {
	margin-bottom: 20px;
}
.widget ul li a {
	margin-bottom: 20px;
	display: inline-block;
	font-weight: 600;
	width: 100%;
}
.widget ul li:last-child,
.wr_widget_recent_posts .wrp-meta a {
	margin: 0;
}
.widget ul li .count {
	color: #b6aea6;
}
.widget.widget_search {
	padding: 0;
	border: 0;
}
.widget ul ul {
	margin-left: 20px;
}
.widget select,
.widget_search .form-group,
.widget_search .form-inline .form-control {
	width: 100%;
}
.widget_search .form-inline .form-control {
	border-color: #e8e6e2;
	height: 50px;
	padding: 0 20px;
	border-radius: 0;
	box-shadow: none;
}
.wr_widget_recent_posts .post-item {
	text-transform: none;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px dashed #e8e6e2;
	overflow: hidden;
}
.wr_widget_recent_posts .post-item:last-child {
	border: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
.wr_widget_recent_posts .post-item a {
	font-size: 13px;
	font-weight: 400;
	color: #39342e;
}
.wr_widget_recent_posts .post-item a:hover {
	color: #95c03e;
}
.wr_widget_recent_posts img {
	float: left;
}
.wr_widget_recent_posts .wrp-meta {
	overflow: hidden;
	padding-left: 18px;
}
.wr_widget_recent_posts .wrp-meta span {
	color: #a0978d;
	display: block;
	margin-top: 5px;
}
.wr_widget_recent_posts .wrp-meta span.price {
	color: #95c03e;
}
.wr_widget_recent_posts .wrp-meta span i {
	margin-right: 5px;
	font-size: 14px;
}
.wr_widget_recent_posts .owl-theme.navtop .owl-controls {
	top: -75px;
	right: -10px;
}
.widget .f-price {
	margin: 22px 0 5px;
	display: table;
}
.widget .f-price input#amount {
	margin: 33px 0 0;
	padding: 0;
	float: right;
	max-width: 50%;
	border: 0;
	color: #b6aea6;
	text-align: right;
	font-weight: 600;
}	
.widget .f-price .btn {
	float: left;
	margin: 25px 0 0;
	background: none;
	font-size: 11px;
	color: #39342e;
	font-weight: 600;
	padding: 10px 20px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.widget .f-price .ui-widget-content {
	background: #e8e6e2;
	position: relative;
}
.widget .f-price .ui-widget-content,
.widget .f-price .ui-slider .ui-slider-range {
	height: 2px;
}
.widget .f-price .ui-slider .ui-slider-range,
.widget .f-price .ui-state-default {
	background: #95c03e;
}
.widget .f-price .ui-slider .ui-slider-range {
	position: absolute;
}
.widget .f-price .ui-state-default {
	width: 10px;
	height: 10px;
	position: absolute;
	top: -4px;
	transition: none;
	-webkit-border-radius: 50%;
	-moz-border-radius:    50%;
	-ms-border-radius:     50%;
	border-radius:         50%;
}
.widget .f-color {
	overflow: hidden;
}
.widget .f-color li {
	float: left;
	width: 35px;
	height: 35px;
	margin: 0 13px 13px 0;
	border: 1px dashed #d1d1d1;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
}
.widget .f-color li:nth-child(5n) {
	margin-right: 0;
}
.widget .f-color li:hover {
	border-color: #95c03e;
}
.widget .f-color li a {
	border: 0;
	display: inline-block;
	float: left;
	font-weight: 400;
	height: 25px;
	line-height: 30px;
	margin-right: 10px;
	position: relative;
	text-indent: -999em;
	width: 25px;
	margin: 4px;
}

/* Button
   ========================================================================== */
.btn-dashed {
	border: 1px dashed #e8e6e2;
	border-radius: 0;
}
.btn-dashed:hover {
	border-color: #95c03e;
	color: #95c03e;
}
.btn-main {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 11px 20px;
	background: #95c03e;
	color: #fff;
	position: relative;
	border: 0;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition:    all .3s ease-in-out;
	transition:         all .3s ease-in-out;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.btn-main:hover {
	background: #78a224;
	color: #fff;
}
.btn-line {
	border: 2px solid #464646;
	padding: 15px 30px;
	font-size: 12px;
	font-weight: 600;
}

/* Image slider
   ========================================================================== */
.image-slider.navinline .owl-controls {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
}

/* Accordion
   ========================================================================== */
.panel {
	box-shadow: none;
}
.panel-default,
.panel-default > .panel-heading {
	border-color: #e8e6e2;
}
.panel-group .panel,
.panel-title i:before {
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.panel-default > .panel-heading {
	background: #fcfcfc;
	padding: 12px 15px;
}
.panel-title {
	font-size: 14px;
}
.panel-title a {
	position: relative;
	padding-left: 20px;
}
.panel-title i {
	font-style: normal;
}
.panel-title i:before,
.panel-title i:after {
	content: "";
	position: absolute;
	left: 0;
}
.panel-title i:before {
	width: 12px;
	height: 12px;
	border: 1px solid #a0978d;
	top: 4px;
}
.panel-title i:after {
	content: "-";
	color: #a0978d;
	top: 1px;
	left: 4px;
}
.panel-title .collapsed i:after {
	content: "+";
	left: 2px;
	top: 2px;
}

/* Testimonial
   ========================================================================== */
.testimonial .quotes {
	padding: 18px 15px;
	border: 1px solid #e8e6e2;
	position: relative;
	margin-bottom: 25px;
	background: #fcfcfc;
	-webkit-border-radius: 2px;
	-moz-border-radius:    2px;
	-ms-border-radius:     2px;
	border-radius:         2px;
}
.testimonial .quotes:after {
	background: #fcfcfc;
	content: "";
	height: 16px;
	position: absolute;
	left: 30px;
	bottom: -7px;
	width: 16px;
	-webkit-box-shadow: 1px 1px 0 0 #e8e6e2;
	-moz-box-shadow:    1px 1px 0 0 #e8e6e2;
	box-shadow:         1px 1px 0 0 #e8e6e2;
	-webkit-transform: rotate(45deg);
	-moz-transform:    rotate(45deg);
	-ms-transform:     rotate(45deg);
	-o-transform:      rotate(45deg);
	transform:         rotate(45deg);
}
.testimonial .avatar {
	float: left;
	overflow: hidden;
	width: 88px;
	height: 88px;
	-webkit-border-radius: 50%;
	-moz-border-radius:    50%;
	-ms-border-radius:     50%;
	border-radius:         50%;
}
.testimonial .bio {
	overflow: hidden;
	padding-left: 20px;
}

/* Member
   ========================================================================== */
.member {
	width: 141px;
	position: relative;
	overflow: hidden;
	color: #a0978d;
}
.member .info {
	width: 100%;
	padding: 12px 0 0;
}
.member .name {
	font-size: 16px;
	font-weight: 600;
	color: #39342e;
	margin: 0;
}
.member .avatar { position: relative; }
.member .position {
	font-size: 12px;
	font-weight: normal;
	position: relative;
}
.member .mask .position:after {
	height: 1px;
	width: 50px;
	background: #a3a3a3;
	content: "";
	position: absolute;
	left: 0;
	bottom: -10px;
}
.member .mask {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #90b945;
	opacity: 0;
	z-index: 10;
	-webkit-transition: all .35s ease 0s;
	-moz-transition:    all .35s ease 0s;
	transition:         all .35s ease 0s;
}
.member .social-member {
	position: absolute;
	z-index: 12;
	top: 40%;
	left: 0;
	width: 100%;
	text-align: center;
	opacity: 0;
	-webkit-transition: all .35s ease 0s;
	-moz-transition:    all .35s ease 0s;
	transition:         all .35s ease 0s;
}
.member .social-member a {
	display: inline-block;
	margin: 0px 2px;
	text-align: center;
	width: 26px;
	height: 26px;
	background: #fff;
	color: #39342e;
	font-size: 14px;
	line-height: 24px;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	-ms-border-radius: 1px;
	border-radius: 1px;
}
.member .social-member a i {
	vertical-align: middle;
}
.member .social-member a:hover {
	background: #39342e;
	color : #fff;
}
.member:hover .mask, .member:hover .social-member {
	opacity: 0.9;
}
.member.style-2 {
	width: 100%;
	background: none;
}
.member.style-2 .info {
	display: none;
}
.member.style-2 .avatar,
.member.style-2 .mask {
	float: left;
}
.member.style-2 .avatar {
	width: 40%;
}
.member.style-2 .mask {
	width: 60%;
}
.member.style-2 .mask {
	position: static;
	background: none;
	color: #353533;
}
.member.style-2 .mask,
.member.style-2:hover img {
	-webkit-transform: none;
	-moz-transform:    none;
	-ms-transform:     none;
	-o-transform:      none;
	transform:         none;
}
.member.style-2 .mask .name {
	font-size: 24px;
	margin-top: -15px;
}
.member.style-2 .avatar {
	position: relative;
}
.member.style-2 .avatar:before,
.member.style-2 .avatar:after {
	content: "";
	position: absolute;
	z-index: 1000;
}
.member.style-2 .avatar:before,
.member.style-2 .avatar:after {
	bottom: 10px;
	content: "";
	left: 10px;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 10px;
	top: 10px;
	-webkit-transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
	-moz-transition:    opacity 0.35s ease 0s, transform 0.35s ease 0s;
	transition:         opacity 0.35s ease 0s, transform 0.35s ease 0s;
}
.member.style-2 .avatar:before {
	border-bottom: 1px solid #fff;
	border-top: 1px solid #fff;
	-webkit-transform: scale(0, 1);
	-moz-transform:    scale(0, 1);
	-ms-transform:     scale(0, 1);
	-o-transform:      scale(0, 1);
	transform:         scale(0, 1);
}
.member.style-2 .avatar:after {
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	-webkit-transform: scale(1, 0);
	-moz-transform:    scale(1, 0);
	-ms-transform:     scale(1, 0);
	-o-transform:      scale(1, 0);
	transform:         scale(1, 0);
}
.member.style-2:hover .avatar:before,
.member.style-2:hover .avatar:after {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform:    scale(1);
	-ms-transform:     scale(1);
	-o-transform:      scale(1);
	transform:         scale(1);
}

@media only screen and (max-width: 414px) and (max-device-width: 414px) {
	.xs-mgt10 { margin-top: 10px; }
	.xs-mgt20 { margin-top: 20px; }
	.xs-mgt30 { margin-top: 30px; }
	.xs-mgt40 { margin-top: 40px; }
	.xs-mgt50 { margin-top: 50px; }
	.xs-mgt60 { margin-top: 60px; }
	.xs-mgt70 { margin-top: 70px; }
	.xs-mgt80 { margin-top: 80px; }
	.xs-mgt90 { margin-top: 90px; }
	.xs-mgt100 { margin-top: 100px; }
	.xs-mgb10 { margin-bottom: 10px; }
	.xs-mgb20 { margin-bottom: 20px; }
	.xs-mgb30 { margin-bottom: 30px; }
	.xs-mgb40 { margin-bottom: 40px; }
	.xs-mgb50 { margin-bottom: 50px; }
	.xs-mgb60 { margin-bottom: 60px; }
	.xs-mgb70 { margin-bottom: 70px; }
	.xs-mgb80 { margin-bottom: 80px; }
	.xs-mgb90 { margin-bottom: 90px; }
	.xs-mgb100 { margin-bottom: 100px; }
	.xs-mgl10 { margin-left: 10px; }
	.xs-mgl20 { margin-left: 20px; }
	.xs-mgl30 { margin-left: 30px; }
	.xs-mgl40 { margin-left: 40px; }
	.xs-mgl50 { margin-left: 50px; }
	.xs-mgr10 { margin-right: 10px; }
	.xs-mgr20 { margin-right: 20px; }
	.xs-mgr30 { margin-right: 30px; }
	.xs-mgr40 { margin-right: 40px; }
	.xs-mgr50 { margin-right: 50px; }
	.xs-pdt10 { padding-top: 10px; }
	.xs-pdt20 { padding-top: 20px; }
	.xs-pdt30 { padding-top: 30px; }
	.xs-pdt40 { padding-top: 40px; }
	.xs-pdt50 { padding-top: 50px; }
	.xs-pdt60 { padding-top: 60px; }
	.xs-pdt70 { padding-top: 70px; }
	.xs-pdt80 { padding-top: 80px; }
	.xs-pdt90 { padding-top: 90px; }
	.xs-pdt100 { padding-top: 100px; }
	.xs-pdb10 { padding-bottom: 10px; }
	.xs-pdb20 { padding-bottom: 20px; }
	.xs-pdb30 { padding-bottom: 30px; }
	.xs-pdb40 { padding-bottom: 40px; }
	.xs-pdb50 { padding-bottom: 50px; }
	.xs-pdb60 { padding-bottom: 60px; }
	.xs-pdb70 { padding-bottom: 70px; }
	.xs-pdb80 { padding-bottom: 80px; }
	.xs-pdb90 { padding-bottom: 90px; }
	.xs-pdb100 { padding-bottom: 100px; }
	.xs-pdl10 { padding-left: 10px; }
	.xs-pdl20 { padding-left: 20px; }
	.xs-pdl30 { padding-left: 30px; }
	.xs-pdl40 { padding-left: 40px; }
	.xs-pdl50 { padding-left: 50px; }
	.xs-pdr10 { padding-right: 10px; }
	.xs-pdr20 { padding-right: 20px; }
	.xs-pdr30 { padding-right: 30px; }
	.xs-pdr40 { padding-right: 40px; }
	.xs-pdr50 { padding-right: 50px; }
}