@media (max-width: 1024px) {
/* Home one */
	.home-1 #left-menu ,
	.home-1 #left-menu .menu {
		width: 230px;
	}
	.home-1 #left-menu .menu > li,
	.home-1 #left-menu .menu li:not(.mega-menu) li {
		padding: 0 10px;
	}
	.home-1 #left-menu .menu li a {
		padding: 10px 0
	}
	.home-1 #left-menu .menu li.parent:after {
		top:9px;
	}
	.logo,
	.menu-box,
	.error-404 {
		text-align: center;
	}
	
	.home-1 .product.style-2 .p-image {
		padding: 0 10px
	}
	.home-1 .product.style-2 .p-image img {
		width: 100%
	}
	.home-1 .products.columns-6 .product.style-2 .p-image {
		padding: 0 5px;
	}
	.home-1 .products.columns-6 .product.style-2 .p-image:before {
		left: 5px;right: 5px
	}
	.home-1 .page-section.v1-two .col-md-6:last-child > div {
		border: none
	}
	.home-1 .products[class*="columns-"] .product {
		margin: 0;
	}
	.home-1 .products.columns-6 .product .p-info .p-action a.add-to-cart {
		display: inline;
		padding: 5px;
		width: auto;
	}
	.home-1 .products.columns-6 .product:nth-child(7n) {
		clear: both;
	}
	.home-1 .products.columns-6 .product .p-info .p-action a.add-to-cart i {
		display:none
	}
	.home-1 .products.columns-6 .product.style-2 .price .amount {
		font-size: 14px
	}
	.home-1 .blog-post .entry-thumb .thumb img,
	.home-1 .product.style-1 .p-image img {
		width: 100%;
	}
	.home-1 .blog-post.style-1 .entry-content {
		margin: -100px 30px 0 30px;
		width: auto;
	}
	.home-1 .client a {
		margin: 0 5px;
	}
	.home-1 .client.pdt40 {
		text-align: center;
		padding-top: 10px;
	}
/* Home two */
	.home-2 .subscribe .form-group .form-control {
		width: 185px;
	}
	.shop-main .products[class*="columns-"] .product {
		padding: 10px;
	}
}
@media (min-width: 1024px) {
/* Home one */
	.home-1 .col-md-6 > .wr-element .product .p-info .p-action a.add-to-cart {
		width: auto;
		display: inline;
		padding: 5px;
		line-height: 30px;
		}
	.home-1 .product.style-1 .p-info {
		padding: 45px 5px 0;
	}
	.home-1 .product .p-info .p-action,
	.home-1 .product .p-countdown {
		margin-top: 15px;
	}
	.home-1 .product .p-countdown > span {
		width: 48px;
		height: 48px;
	}

}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
	.search-cat.navbar-default .input-group .form-control {
		width: 240px
	}
	#related-posts figcaption {
		width: auto;
		margin: -30px 15px 0;
	}
	.home-1 .cart-control {
		width: 150px;
	}

	.home-2 #site-header.v2 .search-cat.navbar-default .input-group .form-control {
		width: 160px;
	}
	.home-2 .menu-box li a {
		padding: 6px 0;
		min-width: 60px;
	}
	.home-2 #main-menu .menu > li > a {
		padding: 6px 13px;
	}
	.home-3 .search-cat.navbar-default .input-group .form-control {
		width: 245px;
	}
	.home-3 .page-section.v3-three .product.style-1 .p-info {
		padding:10px;
	}
	.home-3 .product .p-info .p-action a.add-to-cart {
		width: 40px;
		overflow: hidden;
	}
	.home-3 .products.columns-4 .product {
		padding:10px;
	}
	.home-3 .subscribe.style-2 .form-group .form-control,
	.home-4 .subscribe.style-2 .form-group .form-control{
		width: 290px;
	}
	.cart .cart_totals_inner .coupon .input-text {
		width: 140px;
	}

}
@media  (max-width: 800px) {
	#theme-option .open-close-button {
		position: absolute;
		left: 100%;
	}
	.container,
	.v1-three .blog-post .thumb img,
	.page-section.v2-three .product.style-3 .p-image img,
	.page-section.v3-five .owl-item img,
	.page-section.v3-six .entry-thumb img,
	#site-header.v3 .bot .container,
	.cart form > table,
	.cart .cart-collaterals {
		width: 100%;
	}
	.offline-content .before-countdown {
		width: 270px;
	}

	.page-section.v2-four .products[class*="columns-"] .product {
		width: 50%;
	}
	.products[class*="columns-"] .product {
		width: 33.3%;
	}

	.product .p-info h4 {
		min-height: 35px;
	}
	#p-thumb,
	#site-main > div.shop-main.mgt40 .col-md-3.col-sm-12.col-xs-12 .widget_text.mgb20 {
		text-align: center;
	}
	.search-cat.navbar-default .input-group .form-control {
		width: 254px;
	}
	.page-section.faq-one .search-cat.navbar-default .input-group .form-control {
		width: 338px;
	}
	#site-header.v2 .search-cat.navbar-default .input-group .form-control {
		width: 515px;
	}
	.offline-content > .countdown > span {
		width: 120px;
	}
	.shop-cart {
		float: right;
	}
	#footer-menu .menu li {
		float: none;
	}
	.page-section.v1-two .col-md-6:last-child > div {
		border: 0;
	}
	.page-section.v2-five .product.style-4.has-border:nth-child(2n) {
		border-right: 0;
	}
	.page-section.v2-two .col-md-3:nth-child(3) .wr-element {
		border-left:none;
	}
	.home-2 .page-section.v2-two .container {
		border-right:none;
	}
	.client a {
		margin: 0 1px;
	}
	.page-section.v3-five .head-title,
	.shop-details .row > .col-sm-12 > .product {
		margin-top: 20px;
	}

	#related-posts figcaption {
		width: auto;
		margin: -30px 15px 0;
	}
	.page-section.v3-three .product .p-info .p-action a.add-to-cart,
	.page-section.v3-one .product .p-info .p-action a {
		margin-bottom: 10px;
	}
	.page-section.about-two .col-sm-12 {
		margin-bottom: 40px
	}
	.page-section.v3-one .product.style-1 .p-info {
		margin: 0;
	}
	.page-section.contact-two .form-horizontal .form-group {
		margin: 0 0 15px;
	}
	.cart .cart-collaterals {
		margin: 30px 0 0;
	}
	.banner-text {
		padding-top: 23px;
	}
	.page-section.v2-three .row > .col-md-6:last-child,
	.page-section.v3-five .row .col-md-6:last-child {
		padding-left: 15px;
	}
	.container {
		padding: 0 15px;
	}
	.page-section.v3-three .product.style-1 .p-info {
		padding: 10px;
	}
	.page-section.v3-three .row .col-md-6:last-child > div,
	#site-header.v3 .bot > .container,
	.cart .cart-collaterals {
		padding: 0;
	}
	#site-header .top .dl-menuwrapper button {
		padding: 2px 0;
	}
	.page-section.v3-five .col-md-6:last-child,
	.page-section.v4-three .col-md-7 {
		padding-bottom: 20px;
	}
	.error-404 {
		padding: 50px 0;
	}
	.page-section.v2-five .product.style-4.has-border {
		min-height: 150px;
	}
	
	#site-header .top .dl-menuwrapper li a {
		font-size: 12px;
	}
	.page-section.v2-two .col-md-3:nth-child(2) {
		margin-bottom: 1px;
	}
	.bb-custom-side .bb-text h2 {
		font-size:43px;
	}
/* Home one */
	.home-1 #site-header .bot .col-md-4.col-xs-6 {
		width:38%;
	}
	.home-1 #site-header .bot .col-md-8.col-xs-6 {
		padding: 0;
		width: 62%;
	}
	.home-1 #main-menu .menu > li > a {
		padding:4px;
	}
	.home-1 #left-menu.expand .menu {
		opacity: 0;
	}
	.home-1 #left-menu .menu.active {
		opacity: 1;
	}
	.home-1 #left-menu {
		border:none;
	}
	.home-1 #left-menu button {
		background: #f7f7f7
	}
	.home-1 .col-md-4,
	.home-1 .col-sm-4 {
		padding: 0 10px;
	}
/* Home two */
	#main-menu,
	#left-menu,
	#top-menu,
	.page-section.v2-one .social li:first-child,
	.page-section.v2-one .subscribe label,
	.page-section.v2-one .subscribe .form-group:before {
		display: none;
	}
	.dl-menuwrapper {
		display: block;
	}
	.home-2 .subscribe .form-group .form-control {
		width: 220px;
	}
	.home-2 #site-header.v2 .search-cat.navbar-default .input-group .form-control {
		width: 525px;
	}
	.page-section.v2-two .col-md-3:nth-child(2) {
		border-bottom: 0;
		margin-bottom: 3px;
	}
	.page-section.v2-two .col-md-3:nth-child(3) .wr-element {
		border-left:1px solid #e8e6e2;
	}
	#site-footer.v1 .mid .col-sm-6 {
		min-height: 240px;
	}
/* home three */
	.home-3 .product.style-2 .p-image {
		padding: 10px;
	}
	.home-3 .product.style-2 .p-image:before {
		height: auto;
		top:10px;
		bottom: 10px;
	}
	.home-3 .search-cat.navbar-default .input-group .form-control {
		width: 245px;
	}
}

@media only screen and (max-width: 768px), (max-device-width: 768px) {
	
	.home-2 #site-header.v2 .search-cat.navbar-default .input-group .form-control {
		width: 495px;
	}
	.search-cat.navbar-default .input-group .form-control {
		width: 245px;
	}
}
@media (max-width: 600px){
/* Home one */

	.home-1 .v1-one .wr-element {
		text-align: center;
	}
	.home-1 .v1-one .col-md-3 .icon-box {
		padding: 10px 0;
		border-bottom: 1px solid #eee
	}
	.home-1 .home-1 .v1-one .col-md-3:last-child .icon-box{
		border-bottom: none
	}
	.home-1 .icon-box .icons {
		float:none;
	}
	.home-1 .icon-box .text {
		padding: 0;
	}
	.home-1 .icon-box .text h4 {
		margin-top:0;
	}
	.home-1 .icon-box .text p {
		margin-bottom: 0
	}
	.home-1 .page-section.v1-one .wr-element:before,
	.home-1 .product .p-info .p-action a.add-to-cart .fa,
	.search-cat.navbar-default .nav > li:after{
		display: none
	}
	.search-cat.navbar-default .input-group .form-control {
		width: 100%;
	}
	.search-cat.navbar-default .navbar-left.navbar-form {
		margin-bottom:-1px;
	}
	.home-1 .product.style-1 .p-info .p-action a.add-to-cart {
		width: 100px;
	}

	.home-1 .xs-text-center img.mgb10 {
		margin-left: 10px;
		margin-top:10px;
		height: 200px;
	}
	.home-1 .page-section.pdt50 {
		text-align: center;
	}
	.home-1 .page-section.pdt50 .xs-mgb20 {
		margin-bottom: 20px;
	}
	.home-1 .product-tabs .nav-tabs > li > a ,
	.home-4 .product-tabs .nav-tabs > li > a{
		padding: 10px 5px;
		text-transform: none;

	}
	.product-tabs .nav-tabs .title-1 {
		margin: 10px 0
	}
	.home-1 .product-tabs.right .nav-tabs > li:first-child {
		margin-top:-5px;
	}
	.home-1 .product.style-2 .p-action {
		margin-top:0;
	}
	.home-1 #site-footer.v1 .mid .col-md-4.col-xs-12{
		text-align: center;
	}
	#site-footer.v1 .form-group {
		padding-right: 110px
	}
	.home-2 #site-footer.v1 .form-group,
	.home-3 #site-footer.v1 .form-group,
	.home-4 #site-footer.v1 .form-group {
		padding-right: 0;
	}
	#site-footer.v1 .subscribe .form-group .form-control {
		width: 100%;
	}
	#site-footer.v1 .subscribe .btn {
		position: absolute;
		right: 0;
		top: 0;
	}
	.home-2 #site-footer.v1 .subscribe .btn,
	.home-3 #site-footer.v1 .subscribe .btn,
	.home-4 #site-footer.v1 .subscribe .btn {
		position: static;
	}
	#site-footer.v1 .social {
		text-align:center;
		float:none;
	}
	.home-1 #site-footer.v1 .mid .widget {
		text-align: center;
	}
	.home-1 #site-footer.v1 .mid .widget .contact-info .fa {
		float:none;
		margin: 0;

	}
	#site-header .search-cat.navbar-default{
		clear: both;

	}
	.navbar-default .navbar-collapse {
		display: block;
		border-top:none;
	}
	#site-header .search-cat.navbar-default .navbar-left {
		padding-right: 10px
	}
	.shop-cart {
		float:none;
	}
	.cart-control {
		margin: 0 auto
	}
	.home-3 .page-section.v3-seven .product.style-4 {
		width: 50%;
		float:left;
	}

/* Home two */
	
	.home-2 .social {
		text-align: center;
	}
	.home-2 .subscribe .form-group .form-control {
		float:left;
		width: 450px;
	}
	.home-2 .page-section.pdt30 .xs-text-center .col-md-4 {
		text-align: center;
		padding: 10px;
	}
	.home-2 .product-tabs.style-2 .nav-tabs > li > a,
	.home-2 .product-tabs.style-2 .nav-tabs > li > a:hover,
	.home-2 .product-tabs.style-2 .nav-tabs > li > a:focus {
		padding: 18px 5px;
		border-right: none;
		text-transform: none;
	}
	.home-2 .page-section.v2-four .products .product:nth-child(3),
	.home-2 .page-section.v2-four .products .product:nth-child(5),
	.home-2 .page-section.v2-four .products .product:nth-child(7),
	.home-2 .page-section.v2-two .col-md-3:not(:first-child),
	.page-section.v2-two .col-md-3:nth-child(3) .wr-element {
		border-left:none;
	}
	.home-2 .product .p-info .p-action a.add-to-cart {
		width: 125px;
	}
	.home-2 .page-section.pdt50 .xs-text-center .col-md-4.col-sm-4{
		text-align: center;
	}
	.home-2 .bb-custom-side .bb-text {
		padding: 0 10px;
		font-size: 30px;
	}
	.home-2 .product.style-4 .p-info,
	.home-2 .product.style-4 .p-image {
		text-align: center;
		padding-left:0;
		width: 100%;
	}
	.home-2 .product.style-4 .p-image img {
		display: block;
		margin: 0 auto;
	}
	.home-2 .v2-five .product .p-info .p-action a {
		float:none;
		margin-right: 0;
		display: inline-block;
	}
	.home-2 #site-footer .product.style-4 {
		float:left;

	}
	.home-2 #site-footer .product.style-4 .add-to-cart {
		float:none;
		display: inline-block;
	}
/* home three */
	.home-3 .subscribe.style-2 .btn {
		padding: 9px 20px;
	}
	.home-3 #site-footer.v1 .subscribe.style-2 .form-group .form-control {
		width: 325px;
		float: left;
	}
	.home-3 .product.style-2 .p-info h4 {
		min-height: 35px
	}
	.home-4 #site-footer.v1 .subscribe.style-2 .form-group .form-control {
		width: 310px;
		float:left;
	}
	#site-footer {
		text-align: center;
	}
	#site-footer .mid .fa {
		display: none;
	}
	#site-main > div:nth-child(2) .col-md-2.col-sm-4.col-xs-12 > img.xs-mgb20 {
		margin-bottom:20px;
	}
	#site-main > div:nth-child(2) div.col-md-5.col-sm-8 > img {
		width: 100%;
	}
	#site-main > div.page-section.about-two.pdt50.pdb50 > div > div {
		text-align: center;
	}
	#site-main > div.page-section.about-two.pdt50.pdb50 div.col-md-4.col-sm-6.col-xs-12 > img {
		margin-bottom: 20px;
	}
	#site-main > div.page-section.about-two.pdt50.pdb50 div > img{
		margin: 10px;
	}
	.page-section.about-three .wr-element {
		width: 33.33%;
	}
	.page-section.about-three .wr-element > div {
		text-align: center;
		float: none;
		display: block;
  		margin: 0 auto;
	}
	.blog-main .post .entry-content {
		width: auto;
		margin: -55px 20px 0 20px;
	}
	.page-section.services-two h4.textup {
		margin-top:15px;
	}
	#site-main > div.shop-main.mgt40.mgb60 .col-md-3.col-sm-12.col-xs-12 > aside.widget_text.mgb20 {
		text-align:center;
	}
	#site-main > div.shop-main.mgt40.mgb60 div.col-md-9.col-sm-12.col-xs-12 > nav:nth-child(1) > span.pull-left,
	.shop-main .products.list .product .p-info .p-desc,
	.page-nav .pull-left,
	.coupon label {
		display: none;
	}
	.shop-details .nav-tabs > li {
		margin-right: 15px
	}
	.breadcrumbs {
		padding: 20px;
		text-align: center;
		clear: both;
	}
	.topbar .page-title {
		float:none;
		width: auto;
		margin: 0 -15px;
	}
	.offline-content {
		height: auto
	}
	.offline-content .before-countdown {
		float:none;
		margin: 20px auto;
	}
	.offline-content .countdown {
		text-align: center;

	}
	.offline-content .countdown span:first-child {
		border-left: 1px solid #e8e6e2;
	}
}
@media (max-width: 568px) {
	.home-1 .xs-text-center img.mgb10 {
		margin-left: 5px;
		width: 250px;
	}
	.home-1 .product .p-countdown > span {
		width: 48px;
		height: 48px
	}
	.home-2 .subscribe .form-group .form-control {
		width: 420px;
	}
	.home-3 .v3-one .product .p-info .p-action a.add-to-cart,
	.home-3 .v3-three .product .p-info .p-action a.add-to-cart {
		width: 40px;
		overflow: hidden;
	}
	.home-3 .v3-one .xs-mgb30 {
		margin-bottom: 30px;
		text-align: center;
	}
	.home-3 .v3-one .xs-mgt30 {
		margin-top:30px;
		text-align: center;
	}
	.home-3 #site-footer.v1 .subscribe.style-2 .form-group .form-control {
		width: 295px;
	}
	.home-4 .subscribe.style-2 .form-group .form-control {
		width: 275px;
	}
	.home-4 .tab-content .products[class*="columns-"] .product {
		width: 50%;
	}
	#related-posts figcaption {
		margin: auto;
		padding:0;
		width: auto;
	}
	.woo-cart-empty {
		width: 100%;
	}

}
@media (max-width: 480px){
	.col-sm-4.xs-text-center a {
		display: block;
		width: 50%;
		height: 180px;
		float:left;
		margin-top:5px;
		padding:5px;
	}

	.col-sm-4.xs-text-center img {
		width: 100%;
		max-width: 100%;
		height: 180px;
	}
	.xs-text-center img.mgb10 {
		margin-top:0;
		margin-left:0;
	}
	.product.style-1 .p-info {
		padding: 35px 0;

	}
	.product .p-countdown > span {
		width: 45px;
		height: 45px;
		font-size: 18px;
		line-height: 15px;
	}
	.offline-content .countdown span {
		font-size:30px;
	}
	.offline-content .countdown > span {
		width: 100px;
		height: 150px;
	}
	.shop-main .products[class*="columns-"] .product {
		width: 50%;
		text-align: center;
	}
	.product .p-info .p-action a.add-to-cart {
		width: auto;
		padding: 0 10px;
		height: 27px;
		line-height: 27px;
	}
	.col-md-4.col-sm-4.col-xs-12.xs-text-center a {
		float:none;
		margin: 0 auto;
	}
	.tab-content .products[class*="columns-"] .product:nth-child(4),
	.tab-content .products[class*="columns-"] .product:nth-child(7),
	.tab-content .products[class*="columns-"] .product:nth-child(10){
		clear: both;
	}
	.home-4 .tab-content .products[class*="columns-"] .product:nth-child(4),
	.home-4 .home-4 .tab-content .products[class*="columns-"] .product:nth-child(7),
	.home-4 .tab-content .products[class*="columns-"] .product:nth-child(10){
		clear: none;
	}
	.product.style-1 .p-info .p-action a.add-to-cart {
		height: 40px;
		line-height: 40px;
	}
	.home-2 #site-footer .product.style-4 {
		float:none;
	}
	.home-2 .subscribe .form-group .form-control {
		width: 330px;
	}
	.home-3 #site-footer.v1 .subscribe.style-2 .form-group .form-control {
		width: 200px;
	}
	.home-2 #site-footer.v1 .mid .col-sm-6,
	.home-3 #site-footer.v1 .mid .col-sm-6 {
		min-height: 100px;
	}
	.home-4 #site-footer.v1 .subscribe.style-2 .form-group .form-control {
		width: 190px;
	}
	.home-4 #site-footer {
		text-align: center;
	}
	.page-section.about-three .wr-element {
		width: 50%;
	}
	.shop-main .product .p-info .p-action a.add-to-cart,
	.shop-details .product .p-info .p-action a.add-to-cart{
		height: 40px;
		line-height: 40px;
	}
	.shop-details .nav-tabs > li {
		width: 100%;
		margin-right:0;
		text-align: center;
	}
	.shop-details .nav-tabs > li > a {
		padding: 8px 0;
	}
	#site-main > div.cart .product-thumbnail,
	#site-main > div.cart product-name > span {
		display: none;
	}
}
@media only screen and (min-device-width : 768px), (max-device-width : 1024px), (orientation : landscape) {}
@media only screen and (max-width: 414px), (max-device-width: 414px) {
	#site-header .mid .col-sm-8,
	#site-header.v2 .mid .col-md-5,
	.product-tabs.right .nav-tabs > li:after,
	.banner-text:after,
	.bb-item .bb-custom-side:last-child,
	.page-section.v3-two .col-md-4:before,
	.page-nav .pull-left {
		display: none;
	}
	.xs-text-center,
	.product-tabs.right .nav-tabs > li:first-child,
	#site-footer .social,
	.page-section.v2-one .social,
	.breadcrumbs {
		text-align: center;
	}
	.page-section.v2-one .text-right {
		text-align: left;
	}
	.shop-cart,
	.product.style-1 > div,
	.product-tabs.right .nav-tabs > li:first-child,
	.products[class*="columns-"] .product,
	.page-section.v2-four .products[class*="columns-"] .product,
	.page-section.v3-one .col-xs-12 img,
	.page-section.v3-three .product.style-1 .p-image,
	.page-section.v3-three .product.style-1 .p-image img,
	.page-section.faq-one .search-cat.col-md-6,
	.page-section.about-two .col-sm-6 img,
	#site-footer .social,
	.bb-custom-side,
	.topbar .page-title,
	.comment-respond .comment-form .pull-right + .comment-form-comment,
	.comment-respond .comment-form .pull-right,
	.checkout form .form-row .select2-container,
	.checkout form .form-row input.input-text,
	.checkout form .form-row select,
	.checkout form .form-row textarea.input-text,
	.shop-details #reviews #comments,
	.shop-details #reviews #review_form_wrapper,
	.offline-content .before-countdown {
		width: 100%;
	}
	.newsletter .modal-dialog,
	.p-banner .modal-dialog,
	.blog-main .post .entry-content {
		width: 95%;
	}
	.page-section.about-three .wr-element {
		width: 50%;
	}
	.subscribe.style-2 .form-group .form-control {
		width: 182px;
	}
	.page-section.faq-one .search-cat.navbar-default .input-group .form-control,
	.page-section.testimonial-three .search-cat.navbar-default .input-group .form-control {
		width: 228px;
	}
	.subscribe .form-group .form-control {
		width: 245px;
	}
	.offline-content .subscribe .form-group .form-control {
		width: 370px;
	}
	.offline-content > .countdown > span {
		width: 103.5px;
		height: 100px;
		font-size: 40px;
		border-top: 0;
		padding-top: 8px;
	}
	.cart form {
		overflow-x: scroll;
		overflow-y: hidden;
		width: 384px;
	}
	.search-cat .navbar-nav,
	.search-cat .navbar-left {
		float: left;
	}
	.subscribe .btn {
		float: right;
	}
	.page-section.about-three .wr-element > div,
	.breadcrumbs {
		float: none;
	}
	.breadcrumbs {
		clear: both;
	}
	.subscribe .form-group {
		display: inline-block;
	}
	#site-header .extra-info ul,
	#site-header .extra-info ul li {
		display: inline-block;
	}
	.search-cat .dropdown.open .dropdown-menu {
		position: absolute;
		background: #fff;
		margin-left: -1px;
	}
	.page-section.v2-two .row .col-md-3,
	.search-cat.navbar-default .navbar-left {
		border: 0;
	}
	.offline-content > .countdown > span:last-child {
		border-right: 0;
	}
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(2),
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(3),
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(4),
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(6),
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(7),
	.page-section.v2-four .products[class*="columns-"] .product:nth-child(8) {
		border-left: 0;
	}
	.search-cat.navbar-default .nav > li:after {
		top: 4px;
	}
	#site-header .extra-info ul li {
		margin: 0 5px;
	}
	.product-tabs.right .nav-tabs > li:first-child {
		margin-bottom: 20px
	}
	.cart-control,
	.page-section.about-three .wr-element > div {
		margin: auto;
	}
	.newsletter .modal-dialog {
		margin: 40px auto;
	}
	.products-title,
	.page-section.v3-one .product.style-1 .p-info,
	.breadcrumbs li:first-child,
	.comment-respond .comment-form .form-submit {
		margin: 0;
	}
	.search-cat.navbar-default .navbar-left,
	.blog-main .post .entry-content {
		margin-left: 10px;
	}
	.products.list .p-info .p-action .quickview {
		margin-top: 10px;
	}
	.client a {
		margin: 0 20px;
	}
	.topbar .container,
	.shop-details #reviews #review_form_wrapper {
		padding: 0;
	}
	.page-section.v2-two .col-md-3:first-child {
		padding: 0 15px;
	}
	.p-banner .modal-body .col-md-6:last-child {
		padding: 0 30px;
	}
	.breadcrumbs {
		padding: 30px 0;
	}
	.product-tabs.right .nav-tabs > li a,
	.product-tabs.style-2 .nav-tabs > li > a,
	.product-tabs.style-2 .nav-tabs > li > a:hover,
	.product-tabs.style-2 .nav-tabs > li > a:focus {
		padding: 10px;
		margin: 0;
	}
	.subscribe.style-2 .btn {
		padding: 9px 15px;
		margin-left: 5px;
	}
	#site-footer.v1 .mid .col-sm-6,
	.page-section.v2-five .product.style-4.has-border {
		min-height: inherit;
	}
	.offline-content {
		height: inherit;
	}
	.error-404 h1 {
		font-size: 130px;
	}
}
@media only screen and (max-width: 375px), (max-device-width: 375px) {
	.page-section.v1-three .client,
	.shop-main .products.list .product .p-image {
		text-align: center;
	}
	.blog-post.style-2 .post .entry-thumb,
	.blog-post.style-2 .post > div,
	.subscribe.style-2 .form-group,
	.subscribe.style-2 .form-group .form-control,
	.subscribe.style-2 .btn,
	.page-section.pdt50.pdb50 img.mgb20,
	.page-section.pdt50.pdb50 img.xs-mgb20,
	.page-section.about-two img,
	.shop-main .products.list .product .p-info,
	.shop-main .products.list .product .p-image {
		width: 100%;
	}
	.subscribe .form-group .form-control {
		width: 244px;
	}
	.page-section.faq-one .search-cat.navbar-default .input-group .form-control,
	.page-section.testimonial-three .search-cat.navbar-default .input-group .form-control {
		width: 190px;
	}
	.subscribe .form-group .form-control {
		width: 210px;
	}
	.cart form {
		max-width: 348px;
	}
	.offline-content .subscribe .form-group .form-control {
		width: 325px;
	}
	.offline-content > .countdown > span {
		width: 93.5px;
	}
	.blog-post.style-2 .post .entry-content,
	.blog-post.style-2 .post .entry-thumb,
	.page-section.v1-three .client {
		padding: 0;
	}
	.user-login .coupon .button {
		padding: 0 7px;
	}
	.comments-area .children li {
		margin-left: 0;
	}
	.blog-post.style-2 .post .entry-thumb {
		margin-bottom: 20px
	}
	.client a:last-child {
		margin-right: 20px;
	}
	.subscribe .form-group {
		margin-bottom: 0;
	}
	.products.list .p-info .p-action .quickview {
		margin-top: 0;
	}
	.page-section.about-two img {
		margin: 0 0 30px;
	}
	.error-404 h1 {
		font-size: 100px;
	}
	.dl-menuwrapper .dl-menu {
		width: 200px;
	}
}
@media only screen and (max-width: 320px), (max-device-width: 320px) {
	.blog-main .entry-header > .cat-links {
		display: none;
	}
	.product.style-3 .p-info {
		text-align: center;
	}
	.col-md-4.col-sm-4.col-xs-12.xs-text-center a {
		width: 100%;
	}
	.page-section.about-three .wr-element {
		width: 100%;
	}
	.product .p-info .p-action,
	.product .p-countdown {
		margin-top:10px;
	}
	.product .p-info .p-action a {
		float:none;
		display: inline-block;
		margin: 0 auto;
	}
	.shop-main .products[class*="columns-"] .product {
		width: 100%;
	}
	.home-3 .product .p-info .p-action {
		text-align: center
	}
	.home-3 .v3-one .product .p-info .p-action a.add-to-cart,.home-3 .v3-three .product .p-info .p-action a.add-to-cart {
		margin-bottom:-15px;
	}
	.home-3 .page-section.v3-one .product.style-1 .p-info {
		text-align: center;
	}
	.home-3 .page-section.v3-seven .product.style-4 {
		width: 100%;
	}
	.product .p-info .star-rating {
		display: block;
		margin: 0 auto;
	}
	.product .p-countdown > span {
		display: inline-block;
		float:none;

	}
	#site-header .top .dl-menuwrapper li .fa {
		display: none;
	}
	#site-footer.v1 .social {
		margin-top:10px;
	}
	.col-md-3.col-sm-4.xs-text-center img {
		height: 100px;
	}
	.page-section.v1-two.pdt50 {
		padding-top:0;
	}
	.product.style-3 .p-info .p-title,
	.product.style-3 .p-info .p-action,
	.blog-main .post .entry-content {
		width: 100%;
	}
	.subscribe .form-group .form-control {
		width: 190px;
	}
	.page-section.faq-one .search-cat.navbar-default .input-group .form-control,
	.page-section.testimonial-three .search-cat.navbar-default .input-group .form-control {
		width: 130px;
	}
	.user-login .coupon #coupon_code {
		width: 110px;
	}
	.subscribe .form-group .form-control {
		width: 160px;
	}
	.cart form {
		max-width: 290px;
	}
	.offline-content .subscribe .form-group .form-control {
		width: 274px;
	}
	.offline-content > .countdown > span {
		width: 80px;
		height: 80px;
		font-size: 30px;
	}
	.page-nav a,
	.page-nav .current {
		width: 15px;
	}
	.product.style-3 .p-info .p-action a.add-to-cart {
		float: none;
		margin: 20px auto 10px;
		display: block;
	}
	#top-menu .menu,
	#site-header .extra-info ul {
		padding: 0;
	}
	.blog-main .post .entry-content {
		padding: 10px 0;
	}
	.blog-post.style-1 .entry-content {
		margin: -50px 0 0 10px;
		width: 92%;
	}
	.blog-main .post .entry-content,
	.shop-details #reviews #comments ol.commentlist li .comment-text {
		margin: 0;
	}
	.post-meta .comments-link {
		display: none;
	}
	.menu-box li {
		margin: 0 0 3px;
	}
	.shop-details .product .p-info .p-action a.compare {
		margin-left: 10px;
	}
	.shop-details .product .p-info .p-action a {
		float:left;
	}
	.menu-box li:last-child {
		margin-left: -7px;
	}

	.products.list .p-info .p-action a.add-to-cart,
	.shop-details .product .p-info .p-action a.add-to-cart,
	.shop-details #reviews #comments ol.commentlist li img.avatar {
		margin-right: 10px;
	}
	.home-2 .v2-five .products-title a {
		padding: 10px;
		line-height: 31px;

	}
	.home-2 .page-section.v2-five .product.style-4.has-border:nth-child(2n) {
		border-right:1px solid #e8e6e2;
		margin-left:0;
	}
	.home-2 .subscribe .form-group .form-control {
		width: 200px;
		padding-left: 5px;
	}
	.home-2 .subscribe .btn {
		padding: 9px 5px;
	}
	.home-2 .subscribe  {
		margin-top:10px;
	}
	.home-3 .page-section.v3-three .product.style-1 .p-info {
		text-align: center;
	}
	.home-3 #site-footer.v1 .subscribe.style-2 .form-group .form-control,
	.home-4 #site-footer.v1 .subscribe.style-2 .form-group .form-control,
	.home-4 #site-footer.v1 .tab-content .products[class*="columns-"] .product {
		width: 100%;
	}
	.shop-main .products.list .product .p-info {
		padding-left: 0;
		text-align: center;
	}
	.cart .cart_totals_inner .coupon .input-text {
		width: 120px;
	}
	.shop-details .p-meta ul li span {
		width: 100px;
	}
	.shop-details .p-share span {
		display: none
	}
	.product .p-info .p-action a.add-to-cart {
		width: 40px;
		overflow: hidden;
	}
	.offline-content .before-countdown {
		border-right:none;
		padding: 20px;
	}
	.offline-content .countdown span {
		width: 70px;
		height: 100px;
		font-size: 25px;
	}
	.offline-content > .countdown > span  {
		border: none;
		box-shadow: none;
	}

}