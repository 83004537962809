
.product-detail .p-info a.loading i,
.shop-details .p-info a.loading i {
    -webkit-animation: 2s linear 0s infinite running fa-spin;
    -moz-animation: 2s linear 0s infinite running fa-spin;
    -ms-animation: 2s linear 0s infinite running fa-spin;
    -o-animation: 2s linear 0s infinite running fa-spin;
    animation: 2s linear 0s infinite running fa-spin
}

.product-detail .p-info a.loading i:before,
.shop-details .p-info a.loading i:before {
    content: "\f1ce"
}

.product-detail .p-info a.added i:before,
.shop-details .p-info a.added i:before {
    content: "\f00c"
}

.cart_totals ul.payment_methods {
    list-style: none;
}

.cart-collaterals h5 {
    color: #4f7397;
    margin: 0 0 20px;
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 15px;
}

.shop_table.cart {
    width: 100%;
}

.payment_methods.methods .radio-styled .inner {
    width: 100%;
}

.user-login textarea {
    border: 1px dashed #e8e6e2;
    background: #fff;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: #E64F96;
    border-color: #E64F96;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span{
    border-radius: 0;
}

.widget_categories a.active {
    color: #E64F96;
}

.search-cat.navbar-default .navbar-left,
.search-cat.navbar-default .navbar-left .input-group {
    width: 100%;
}

#site-header .top .lang-active a {
    color: #E64F96;
}

.product .p-info .p-action a.add-to-cart {
    width: 170px;
    height: 40px;
}

.product-label {
    width: 175px;
}

.product-price {
    width: 110px;
}

#site-footer .contact-info li i {
    padding-left: 1px;
    margin-top: 0;
    width: 10px;
    position: absolute;
    margin-left: -30px;
    line-height: 18px;
}

#site-footer .contact-info {
    margin-left: 30px;
}

#site-header .top a {
    color: #fff;
}

.form-group.required .control-label:after{
    content: ' *';
    width: 1px;
    height: 1px;
    position: absolute;
    color: #ff5555;
}

.checkout button[disabled] {
    background: #666;
}